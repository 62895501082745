.comment {
    word-break: break-all;
}

.comment-field {
    height: 12em;
}

div.comment-field {
    overflow-y: auto;
}

.votebar-container {
    position: relative;
    margin-top: 6px;
}

.votebar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: var(--secondary);
}
